import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pill-button',
  templateUrl: './pill-button.component.html',
  styleUrls: ['./pill-button.component.scss']
})
export class PillButtonComponent implements OnInit {
  @Input() label: string;
  @Input() icon: string;
  @Input() force: string;
  @Input() stroked = false;
  
  constructor() { }

  ngOnInit(): void {
  }
}
