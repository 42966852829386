<div 
    [ngClass]="{ 
        'team-badge': true, 
        'disabled': disabled,
        'enabled': !disabled,
        'selected': selected }"
    [ngStyle]="{ 'width': size + 'px' }">
    
    <div class="team-image-container" 
        [ngClass]="{'placeholder': !team.cover}"
        [ngStyle]="{ 'height': size + 'px' }"
        (click)="onClick()">
        <div class="image" style="background-image: url({{team.cover}});" >        
        </div>
        <div class="disabled-overlay" *ngIf="disabled || !team.cover">
            <div class="disabled-text" *ngIf="disabled && disabledText">
                {{disabledText}}
            </div>
        </div>
    </div>

    <div class="mat-subheading-2 team-name">{{team.name}}</div>
    
    <app-pill-button 
        [label]="team.members.length + (team.members.length > 1 ? ' members' : ' member')" 
        [icon]="'people_alt'"
        [force]="'off'">
    </app-pill-button>
</div>
