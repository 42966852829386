import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundPageComponent } from './utils/not-found-page/not-found-page.component';
import { AuthGuard } from './_helpers/auth.guard';
import { Role } from './_models/role';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const onboardingModule = () => import('./onboarding/onboarding.module').then(x => x.OnboardingModule);
const memberDashboardModule = () => import('./member-dashboard/member-dashboard.module').then(x => x.MemberDashboardModule);
const adminDashboardModule = () => import('./admin-dashboard/admin-dashboard.module').then(x => x.AdminDashboardModule);

const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'member-dashboard', 
    pathMatch: "full" 
  },
  { 
    path: 'account', 
    loadChildren: accountModule 
  },
  { 
    path: 'onboarding', 
    loadChildren: onboardingModule, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'member-dashboard', 
    loadChildren: memberDashboardModule, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'admin-dashboard', 
    loadChildren: adminDashboardModule, 
    canActivate: [AuthGuard], 
    data: { role: Role.Contributor } 
  },
  { 
    path: 'not-found', 
    component: NotFoundPageComponent, 
    data: { notFound: true }
  },
  { 
    path: '**', 
    redirectTo: '/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
