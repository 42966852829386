import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router'

import { AccountService } from '../_services/account.service';

@Injectable()
export class LoginRedirector implements HttpInterceptor {
    constructor(
        private accountService: AccountService,
        private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // Auto logout only if authentication error when the user is authenticated
            // Otherwise it redirects at bootstrap when trying to refresh the token in background
            if ([401].includes(err.status) && this.accountService.currentToken) {
                console.log(`Received a 401 accessing ${request.urlWithParams}, the token is ${this.accountService.currentToken.accessToken}, redirecting to login...`)
                // Auto logout if 401 response returned from api
                this.accountService.signout();
                this.router.navigate(['/account/login'], { queryParams: { redirect: request.urlWithParams }});
            }
            return throwError(err);
        }))
    }
}