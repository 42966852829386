<div class="dialog-container">
    <div class="mat-title dialog-title">
        {{data.title}}
    </div>
    <div class="dialog-content">
        {{data.content}}
    </div>
    <div class="row">
        <div *ngFor="let action of data.actions">
            <button *ngIf="action.type === 'neutral'" mat-stroked-button [mat-dialog-close]="action.id">{{action.label}}</button>
            <button *ngIf="action.type === 'warning'" mat-flat-button color="warn"[mat-dialog-close]="action.id">{{action.label}}</button>
            <button *ngIf="action.type === 'suggested'" mat-flat-button color="primary"[mat-dialog-close]="action.id">{{action.label}}</button>
        </div>
    </div>
</div>
