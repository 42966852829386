import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class GlobalLoadingService {  
  private loadingSubject = new BehaviorSubject<Boolean>(false)
  public loading: Observable<Boolean>
  private loadingMessageSubject = new BehaviorSubject<string>(null)
  public loadingMessage: Observable<String>

  constructor() {
    this.loading = this.loadingSubject.asObservable()
    this.loadingMessage = this.loadingMessageSubject.asObservable()
  }

  public startLoading(message?: string) {
    this.loadingSubject.next(true)
    this.loadingMessageSubject.next(message)
  }

  public stopLoading() {
    this.loadingSubject.next(false)
    this.loadingMessageSubject.next(null)
  }
}
