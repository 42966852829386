<div class="image-uploader-container" [ngClass]="{'placeholder': !image, 'circular': circular}" [ngStyle]="{ 'width': width, 'height': height } ">
    <div class="image-actions-container">
        <div *ngIf="image && image.length > 0" class="image" style="background-image: url({{image}});" >        
        </div>
        <div class="overlay" *ngIf="uploadProgress == 0">
            <div *ngIf="!disabled" class="button-container">
                <a (click)="onSelectPicture()">
                    <mat-icon aria-hidden="false" aria-label="Set a picture">edit</mat-icon>
                </a>
                <a *ngIf="image && image.length > 0" (click)="onDeletePicture()">
                    <mat-icon aria-hidden="false" aria-label="Remove the picture">delete</mat-icon>
                </a>    
            </div>
        </div>
    </div>
    <div class="progress" *ngIf="uploadProgress != 0">
        <div class="progress-percentage mat-subheading-2">
            Uploading
        </div>
        <svg>
            <circle *ngIf="uploadProgress > 0" cx="50%" cy="50%" [attr.r]="progressRadius" [ngStyle]="{ 'stroke-dasharray': circumference, 'stroke-dashoffset': offset }"></circle>
        </svg>
    </div>
    <input id="file-input" type="file" #file (change)="onPictureSelected()"/>
</div>
