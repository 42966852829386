import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalLoadingService } from 'src/app/_services/global-loading.service';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss']
})
export class GlobalLoaderComponent implements OnInit {
  loading: Observable<Boolean>
  loadingMessage: Observable<String>

  constructor(private loadingService: GlobalLoadingService) { }
  
  ngOnInit(): void {
    this.loading = this.loadingService.loading;
    this.loadingMessage = this.loadingService.loadingMessage;
  }
}
