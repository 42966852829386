<div class="notification-container shadowed rounded-corners">  
    <div *ngIf="date" class="notification-date mat-caption">
        {{(date | date:'medium')}}
    </div>  
    <div class="mat-body-2 notification-title">
        {{title}}
    </div>
    <div class="notification-content">
        {{content}}
    </div>
    <div class="row small-row-padding" *ngIf="actionTitle || dismissTitle">
        <app-pill-button 
            *ngIf="actionTitle"
            [label]="actionTitle" 
            [icon]="actionIcon" 
            [force]="'on'"
            (click)="onAction()">
        </app-pill-button>
        <app-pill-button 
            *ngIf="dismissTitle"
            [label]="dismissTitle" 
            (click)="onDismiss()">
        </app-pill-button>
    </div>
</div>
