import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DecoratedMission } from 'src/app/_models/mission';

@Component({
  selector: 'app-mission-manifest',
  templateUrl: './mission-manifest.component.html',
  styleUrls: ['./mission-manifest.component.scss']
})
export class MissionManifestComponent implements OnInit, OnChanges {
  @Input() mission: DecoratedMission;
  @Output() join = new EventEmitter();
  
  missionDescriptionHtml: SafeHtml

  constructor(
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // Sanitize Html description of the mission
    if(this.mission)
      this.missionDescriptionHtml = this.sanitizer.bypassSecurityTrustHtml(this.mission.description);   
  }

  ngOnChanges() {
    if(this.mission)
      this.missionDescriptionHtml = this.sanitizer.bypassSecurityTrustHtml(this.mission.description);   
  }

  onJoin() {
    this.join.emit();
  }
}
