import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeadingTitleComponent } from './heading-title/heading-title.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { MatIconModule } from '@angular/material/icon';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { PillButtonComponent } from './pill-button/pill-button.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { GlobalLoaderComponent } from './global-loader/global-loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationComponent } from './notification/notification.component';
import { SmallMissionBoxComponent } from './small-mission-box/small-mission-box.component';
import { MissionBoxComponent } from './mission-box/mission-box.component';
import { TeamBadgeComponent } from './team-badge/team-badge.component';
import { InformationBoxComponent } from './information-box/information-box.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { MissionManifestComponent } from './mission-manifest/mission-manifest.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { PaymentComponent } from './payment/payment.component';

@NgModule({
  declarations: [
    HeadingTitleComponent,
    DashboardHeaderComponent,
    ImageUploaderComponent,
    PillButtonComponent,
    ConfirmationDialogComponent,
    SidebarComponent,
    GlobalLoaderComponent,
    NotificationComponent,
    SmallMissionBoxComponent,
    MissionBoxComponent,
    TeamBadgeComponent,
    InformationBoxComponent,
    FileUploaderComponent,
    MissionManifestComponent,
    NotFoundPageComponent,
    PaymentComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    RouterModule
  ],
  exports: [ 
    HeadingTitleComponent,
    DashboardHeaderComponent,
    ImageUploaderComponent,
    PillButtonComponent,
    ConfirmationDialogComponent,
    SidebarComponent,
    GlobalLoaderComponent,
    NotificationComponent,
    MissionBoxComponent,
    TeamBadgeComponent,
    InformationBoxComponent,
    FileUploaderComponent,
    MissionManifestComponent,
    NotFoundPageComponent,
    PaymentComponent
  ]
})
export class UtilsModule { }
