import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccessTokenInjector } from './_helpers/access-token-injector';
import { LoginRedirector } from './_helpers/login-redirector'
import { CommonModule } from '@angular/common';
import { refreshToken } from './_helpers'
import { AccountService } from './_services/account.service'
import { UtilsModule } from './utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MemberDashboardRoutingModule } from './member-dashboard/member-dashboard-routing.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    MatSnackBarModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    UtilsModule,
    MatNativeDateModule,
    MatNativeDateModule,
    MatButtonModule,
    MatInputModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTableModule,
    MatSelectModule,
    MatInputModule,
    MatSortModule,
    MatIconModule,
    MatDatepickerModule,
    MatProgressSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInjector, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoginRedirector, multi: true },
    { provide: APP_INITIALIZER, useFactory: refreshToken, multi: true, deps: [AccountService, Router] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
