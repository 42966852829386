import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DecoratedMission, MissionProject } from 'src/app/_models/mission';
import { Team } from 'src/app/_models/team';

@Component({
  selector: 'app-mission-box',
  templateUrl: './mission-box.component.html',
  styleUrls: ['./mission-box.component.scss']
})
export class MissionBoxComponent implements OnInit {
  @Input() mission: DecoratedMission
  @Input() project: MissionProject
  @Input() team: Team
  @Input() shadowOff: boolean

  @Output() join = new EventEmitter<void>()
  @Output() edit = new EventEmitter<void>()
  @Output() view = new EventEmitter<void>()
  
  constructor() { }

  ngOnInit(): void {
  }

  onJoin() {
    this.join.emit()
  }

  onEdit() {
    this.edit.emit()
  }

  onView() {
    this.view.emit()
  }
}
