<div class="mission-box" [ngClass]="{ 'mission-box-shadow-off': shadowOff }">
    <div class="mission-box-cover" style="background-image: url({{mission.cover}});">
    </div>
    <div class="mission-box-content">
        <div class="mission-box-content-header" *ngIf="project && team">
            <div class="mission-box-content-deadline mat-caption">
                Deadline {{(mission.endDate | date:'mediumDate')}}
            </div>
            <div class="mission-box-content-award mat-caption" *ngFor="let award of mission.awardsForTeam(team._id)">
                <mat-icon>emoji_events</mat-icon>
                <span>
                    {{ award.title }}
                </span>
            </div>
        </div>
        <div class="mission-box-content-center">
            <h1 class="mission-box-content-title">
                {{mission.title}}
            </h1>
        </div>
        <div class="mission-box-content-footer" *ngIf="project && team">
            <div class="mission-box-content-project">
                <div class="mission-box-content-project-title mat-subheading-2" *ngIf="project.title">
                    {{project.title}}
                </div>
                <div class="mission-box-content-team-code mat-caption">
                    Team code: {{project.teamId | uppercase}}
                </div>
            </div>
            <div class="mission-box-content-buttons">
                <app-pill-button 
                    [label]="team.members.length" 
                    [icon]="'people_alt'"
                    [stroked]="true"
                    [force]="'off'">
                </app-pill-button>
                <app-pill-button 
                    *ngIf="mission.status != 'past' && mission.missingInfo(team._id).length == 0"
                    [label]="'Complete'" 
                    [icon]="'check'"
                    [stroked]="true"
                    [force]="'off'">
                </app-pill-button>
                <app-pill-button 
                    *ngIf="mission.status != 'past'"
                    [label]="'Edit'" 
                    [icon]="'edit'"
                    (click)="onEdit()">
                </app-pill-button>
                <app-pill-button 
                    *ngIf="mission.status === 'past'"
                    [label]="'View'"
                    (click)="onView()">
                </app-pill-button>
            </div>
        </div>
        <div class="mission-box-content-footer" *ngIf="!project || !team">
            <div class="mission-box-content-info">
                <div class="mission-box-content-subtitle" *ngIf="mission.subtitle">
                    {{mission.subtitle}}
                </div>
                <div class="mission-box-content-deadline mat-caption">
                    Deadline {{(mission.endDate | date:'mediumDate')}}
                </div>
            </div>
            <div class="mission-box-content-buttons">
                <div class="mission-box-content-button-join">
                    <app-pill-button 
                        [label]="'Join'" 
                        [icon]="'arrow_forward'"
                        (click)="onJoin()">
                    </app-pill-button>
                </div>
            </div>
        </div>
    </div>
</div>
