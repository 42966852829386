import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-box',
  templateUrl: './information-box.component.html',
  styleUrls: ['./information-box.component.scss']
})
export class InformationBoxComponent implements OnInit {
  @Input() title: string
  @Input() content: string
  @Input() type: string

  constructor() { }

  ngOnInit(): void {
  }
}
