import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegistrationInfo } from "../_models/registration-info"

import { Profile } from "../_models/profile";
import { TokenInfo } from "../_models/token-info";
import { DataSourceSlice } from '../_helpers';
import { FileUploaderDownloader } from '../_helpers/file-uploader-downloader';

const baseUrl = `${environment.apiUrl}`;

@Injectable({ providedIn: 'root' })
export class AccountService {
    private profileSubject: BehaviorSubject<Profile>;
    private tokenSubject: BehaviorSubject<TokenInfo>;
    public profile: Observable<Profile>;
    public token: Observable<TokenInfo>;

    public get currentProfile(): Profile {
        return this.profileSubject.value;
    }

    public get currentToken(): TokenInfo {
        return this.tokenSubject.value;
    }

    private refreshTokenTimeout;

    constructor(private http: HttpClient) {
        this.profileSubject = new BehaviorSubject<Profile>(null);
        this.profile = this.profileSubject.asObservable();

        this.tokenSubject = new BehaviorSubject<TokenInfo>(null);
        this.token = this.tokenSubject.asObservable();
    }

    signup(registrationInfo: RegistrationInfo, invitationToken?: string) {
        return this.http.post(`${baseUrl}/accounts` + (invitationToken ? "?invitationToken=" + invitationToken : ""), registrationInfo);
    }

    signin(email: string, password: string) {
        return this.http.post<TokenInfo>(`${baseUrl}/auth/signin`, { email, password }, { withCredentials: true })
            .pipe(map(response => {
                this.tokenSubject.next(response);
                this.startRefreshTokenTimer();
                return response;
            }));
    }

    signout() {
        this.http.post<any>(`${baseUrl}/auth/signout`, {}, { withCredentials: true }).subscribe();
        this.stopRefreshTokenTimer();
        this.tokenSubject.next(null);
        this.profileSubject.next(null);
    }

    refreshToken() {
        return this.http.post<TokenInfo>(`${baseUrl}/auth/refresh`, {}, { withCredentials: true })
            .pipe(map(response => {
                this.tokenSubject.next(response);
                console.log("Token refreshed: " + JSON.stringify(this.tokenSubject.value));
                this.startRefreshTokenTimer();
                return response;
            }));
    }

    sendVerification(email: string) {
        return this.http.post(`${baseUrl}/accounts/verification`, { email }, { observe: 'response' });
    }

    verifyAccount(verificationToken: string) {
        return this.http.post(`${baseUrl}/accounts/verification/${verificationToken}`, { observe: 'response' });
    }

    resetPassword(email: string) {
        return this.http.post(`${baseUrl}/accounts/password`, { email }, { observe: 'response' });
    }

    updatePassword(resetToken: string, newPassword: string) {
        return this.http.post(`${baseUrl}/accounts/password/${resetToken}`, { password: newPassword });
    }

    getProfile() {
        return this.http.get<Profile>(`${baseUrl}/accounts/me`)
        .pipe(map(response => {
            this.profileSubject.next(response);
            return response;
        }));
    } 

    updateProfile(profile: Profile) {
        return this.http.post<Profile>(`${baseUrl}/accounts/me`, profile)
        .pipe(map(response => {
            this.profileSubject.next(response);
            return response;
        }));
    } 

    deleteAccount() {
        return this.http.delete<Profile>(`${baseUrl}/accounts/me`)
        .pipe(map(response => {
            this.profileSubject.next(null);
            this.tokenSubject.next(null);
            return response;
        }));
    }

    setProfilePicture(file: File): Observable<[Number, Profile]> {
        let uploader = new FileUploaderDownloader(this.http);
        return uploader.upload<Profile, Profile>(
            `${baseUrl}/accounts/me/picture`, file, 'picture', p => p)
        .pipe(map(response => {
            if(response[1])
                this.profileSubject.next(response[1]);
            return response;
        }));

        // const formData: FormData = new FormData();
        // formData.append('picture', file, file.name);
        // return this.http.post<Profile>(`${baseUrl}/accounts/me/picture`, formData)
        // .pipe(map(response => {
        //     this.profileSubject.next(response);
        //     return [100, response];
        // }));
    }

    deleteProfilePicture() {
        return this.http.delete<Profile>(`${baseUrl}/accounts/me/picture`)
        .pipe(map(response => {
            this.profileSubject.next(response);
            return response;
        }));
    }

    /* Admin */

    getSpecificProfiles = (userIds?: string[], sort?: String, order?: "asc" | "desc", page?: Number, pageSize?: Number) => {
        let filter = userIds ? "&userIds=" + userIds.join(",") : ""
        filter = filter + (sort ? "&sort=" + sort : "") 
        filter = filter + (order ? "&direction=" + order : "") 
        filter = filter + (pageSize ? "&pageSize=" + pageSize : "") 
        filter = filter + (page && pageSize ? "&offset=" + page.valueOf() * pageSize.valueOf() : "") 
        filter = filter.length > 0 ? "?" + filter.substring(1) : "";

        return this.http.get<DataSourceSlice<Profile>>(`${baseUrl}/admin/accounts` + filter)
    }

    getAllProfiles(sort?: String, order?: "asc" | "desc", page?: Number, pageSize?: Number) {
        return this.getSpecificProfiles(null, sort, order, page, pageSize)
    }

    setRoles(userIds: string[], role: string) {
        return this.http.post<any>(`${baseUrl}/admin/accounts/roles`, { "userIds": userIds, "role": role })
    }

    verifyAccounts(userIds: string[]) {
        return this.http.post<any>(`${baseUrl}/admin/accounts/verification`, userIds)
    }

    deleteAccounts(userIds: string[]) {
        return this.http.request<any>('DELETE', `${baseUrl}/admin/accounts`, { body: userIds })
    }

    resetPasswords(userIds: string[]) {
        return this.http.post<any>(`${baseUrl}/admin/accounts/password`, userIds)
    }

    resetTokens(userIds: string[]) {
        return this.http.request<any>('DELETE', `${baseUrl}/admin/accounts/refresh-token`, { body: userIds })
    }

    // Helper methods to refresh token regularly

    private startRefreshTokenTimer() {
        const accessToken = this.currentToken.accessToken;
        
        // Parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));

        // Set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        if(timeout > 0)
            this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}