import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-small-mission-box',
  templateUrl: './small-mission-box.component.html',
  styleUrls: ['./small-mission-box.component.scss']
})
export class SmallMissionBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
