import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @Input() title: string
  @Input() teamId: string
  @Input() amount: number
  @Input() status: string
  @Input() reference: string
  @Input() date: Date

  constructor() { }

  ngOnInit(): void {
  }
}
