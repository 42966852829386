<div class="payment-container shadowed rounded-corners">  
    <div class="payment-date mat-caption">
        {{(date | date:'medium')}}
    </div>
    <div class="general">
        <div class="info">
            <div class="mat-body-2 payment-title">
                {{title}}
            </div>
            <div class="payment-team">
                Team identifier: {{teamId | uppercase}}
            </div>
        </div>
        <div class="payment payment-{{status}}">
            <div class="payment-amount mat-title">
                {{amount}}€
            </div>  
            <div class="payment-status mat-caption">
                Payment <span>{{status == 'succesful' ? "accepted" : (status == 'pending'? "pending" : "failed")}}</span>
            </div>
        </div>
    </div>
    <div class="reference mat-caption">
        Ref: {{reference}}
    </div>
</div>
