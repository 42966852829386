import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef, AfterViewInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
  // Ref to the file input
  @ViewChild('file') fileInput;

  @Input() image: string;
  @Input() disabled: boolean;
  @Input() circular = true;
  @Input() width = "200px";
  @Input() height = "200px";
  @Input() progressRadius = 100;

  @Input() uploadProgress: number = 0;

  @Output() fileChanged = new EventEmitter<File>();

  get circumference() {
    return this.progressRadius * 2 * Math.PI;
  }
  get offset() {
    return this.uploadProgress ? this.circumference - this.uploadProgress / 100 * this.circumference : 0
  }

  constructor() { }

  ngOnInit(): void {
  }

  onPictureSelected() {
    const files: FileList = this.fileInput.nativeElement.files;
    if(files.length > 0) {
      this.fileChanged.emit(files.item(0));
    }
  }

  onDeletePicture() {
    this.fileChanged.emit(null);
  }

  onSelectPicture() {
    this.fileInput.nativeElement.click();
  }
}
