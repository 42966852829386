import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpEventType } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Router } from '@angular/router'

import { AccountService } from '../_services/account.service';

export class FileUploaderDownloader {
    constructor(
        private httpClient: HttpClient) { }

    upload<T, U>(url: string, file: File, fieldName: string, responseFinalizer: (data: T) => U): Observable<[Number, U]> {
        const formData: FormData = new FormData();
        formData.append(fieldName, file, file.name);
        return this.httpClient.post(url, formData,
        {
            reportProgress: true,
            observe: 'events'
        })
        .pipe(filter(evt => {
            return evt.type == HttpEventType.Sent || evt.type == HttpEventType.UploadProgress || evt.type == HttpEventType.Response 
        }))
        .pipe(map(evt => {
            switch (evt.type) {
                case HttpEventType.Sent:
                    return [0, null];
                case HttpEventType.UploadProgress:
                    return [Math.round(evt.loaded * 100 / evt.total), null];
                case HttpEventType.Response:
                    return [100, responseFinalizer(evt.body as T)];
            }  
        }))
    }

    download(url: string): Observable<[Number, Blob]> {
        return this.httpClient.get(url,
        {
            reportProgress: true,
            responseType: 'blob',
            observe: 'events'
        })
        .pipe(filter(evt => evt.type == HttpEventType.Sent || evt.type == HttpEventType.DownloadProgress || evt.type == HttpEventType.Response ))
        .pipe(map(evt => {
            switch (evt.type) {
                case HttpEventType.Sent:
                    return [0, null];
                case HttpEventType.DownloadProgress:
                    return [Math.round(evt.loaded * 100 / evt.total), null];
                case HttpEventType.Response:
                    return [100, evt.body];
            }  
        }))
    }
}