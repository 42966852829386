import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AccountService } from '../_services/account.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const account = this.accountService.currentProfile;
        if (account) {
            // check if route is restricted by role
            if (route.data.role && !account.roles.includes(route.data.role)) {
                // role not authorized so redirect to home page
                this.router.navigate(['/']);
                return false;
            }
            // authorized so return trueroute
            return true;
        }
        
        if(this.accountService.currentToken)
            console.log(`Auth guard: the account doesn't exist when accessing ${state.url}, the token is ${this.accountService.currentToken.accessToken}, redirecting to login...`)

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/login'], { queryParams: { redirect: state.url }});
        return false;
    }
}