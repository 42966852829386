import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  // Ref to the file input
  @ViewChild('file') fileInput;
  
  @Input() currentFile: String
  @Input() title: String
  @Input() allowedExtensions: String[]
  @Input() maxSize: Number
  @Input() enabled = true

  @Input() actionState: any

  @Output() fileChanged = new EventEmitter<File>()
  @Output() delete = new EventEmitter<void>()
  @Output() view = new EventEmitter<void>()
  
  constructor() { }

  ngOnInit(): void {
  }

  onUpload() {
    if(!this.currentFile && this.enabled)
      this.fileInput.nativeElement.click();
  }
  
  onChange() {
    if(this.enabled)
      this.fileInput.nativeElement.click();
  }

  onRemove() {
    if(this.enabled)
      this.delete.emit();
  }

  onView() {
    this.view.emit();
  }

  onFileSelected() {
    if(this.enabled) {
      const files: FileList = this.fileInput.nativeElement.files;
      if(files.length > 0) {
        this.fileChanged.emit(files.item(0));
      }
    }
  }
}
