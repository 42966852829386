<div class="file-uploader" [ngClass]="{ 'disabled': !enabled, 'populated': currentFile }" (click)="onUpload()">
    <div class="title mat-subheading-2">
        {{currentFile ? currentFile : title}}
    </div>
    <div class="actions">
        <mat-icon *ngIf="currentFile" (click)="onView()">visibility</mat-icon>
        <mat-icon *ngIf="currentFile" (click)="onChange()">edit</mat-icon>
        <mat-icon *ngIf="currentFile" (click)="onRemove()">remove_circle</mat-icon>
        <mat-icon *ngIf="!currentFile">cloud_upload</mat-icon>
    </div>
</div>
<div class="hint" *ngIf="(allowedExtensions || maxSize) && !actionState">
    <span *ngIf="allowedExtensions">Accepted formats: {{allowedExtensions.join(", ")}}</span>
    <span *ngIf="allowedExtensions && maxSize">. </span>
    <span *ngIf="maxSize">Max size: {{maxSize}}MB</span>
</div>
<div class="hint" *ngIf="actionState && actionState.action">
    <span>{{actionState.action}}</span>
</div>
<mat-progress-bar *ngIf="actionState && actionState.progress" mode="determinate" value="{{actionState.progress}}">
</mat-progress-bar>
<input id="file-input" type="file" #file (change)="onFileSelected()" style="visibility: collapse; display: none;"/>
