<div class="dashboard-container" [ngClass]="{ 'dashboard-container-404': is404 }">
    <app-dashboard-header 
        *ngIf="(profile | async) && !is404"
        [forAdmin]="(profile | async) && (profile | async).roles.includes('contributor') && adminView" 
        [showSwitch]="(profile | async) && (profile | async).roles.includes('contributor')" 
        (switch)="onSwitchDashboard()">
    </app-dashboard-header>

    <!-- 404 header -->
    <app-dashboard-header 
        *ngIf="is404"
        [forAdmin]="false" 
        [transparent]="true"
        [showSwitch]="false">
    </app-dashboard-header>

    <div class="dashboard-content-container">
        <div *ngIf="(profile | async) && !is404"
            class="dashboard-sidebar">
            <app-sidebar [items]="menuItems"></app-sidebar>
        </div>
        <div class="dashboard-content">
            <router-outlet></router-outlet>
            
            <!-- Global loader/spinner -->
            <app-global-loader></app-global-loader>
        </div>
    </div>
    
    <!-- No need for footer (which could overlap some elements) in admin view -->
    <div class="dashboard-footer" *ngIf="!adminView">
        <span class="disclaimer">
            © 2021 ReUse Italy. All Rights reserved
        </span>
        <span class="separator">|</span>
        <a href="/">ReUse Italy Homepage</a>
        <span class="separator">|</span>
        <a href="https://www.reuseitaly.com/privacy-and-cookie-policy/">Privacy Policy</a>
        <span class="separator">|</span>
        <a href="https://www.reuseitaly.com/tearms-and-conditions/">Terms & Conditions</a>
    </div>
</div>
