import { CollectionViewer, DataSource } from '@angular/cdk/collections'
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Profile } from 'src/app/_models/profile';
import { AccountService } from 'src/app/_services/account.service';
import { GlobalLoadingService } from 'src/app/_services/global-loading.service';

export class DataSourceSlice<T> {
    elements: T[];
    count: Number;
    totalCount: Number;
}

export class GenericDataSource<T> implements DataSource<T> {
    private errorSubject = new BehaviorSubject<any>(null)
    private usersSubject = new BehaviorSubject<T[]>([])
    private totalCountSubject = new BehaviorSubject<Number>(0)

    public error = this.errorSubject.asObservable()
    public totalCount = this.totalCountSubject.asObservable()

    public pageSize: Number = null
    public sort: string = null
    public direction: string = null
    public currentPage = 0
    
    constructor(
        private dataFetcher: (sort: String, direction: String, currentPage: Number, pageSize: Number) => Observable<DataSourceSlice<T>>,
        private loadingService: GlobalLoadingService) {}

    connect(collectionViewer: CollectionViewer): Observable<T[] | readonly T[]> {
        return this.usersSubject.asObservable()
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.errorSubject.complete()
        this.usersSubject.complete()
        this.totalCountSubject.complete()
    }

    refresh() {
        this.loadingService.startLoading()

        this.dataFetcher(this.sort, this.direction === "asc" ? "asc" : "desc", this.currentPage, this.pageSize)
        .pipe(finalize(() => {
            this.loadingService.stopLoading()
        }))
        .subscribe({
            next: slice => {
                this.errorSubject.next(null)
                this.totalCountSubject.next(slice.totalCount)
                this.usersSubject.next(slice.elements)
            },
            error: err => {
                this.errorSubject.next(err)
            }
        })
    }
}