import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Team } from 'src/app/_models/team';

@Component({
  selector: 'app-team-badge',
  templateUrl: './team-badge.component.html',
  styleUrls: ['./team-badge.component.scss']
})
export class TeamBadgeComponent implements OnInit {
  @Input() disabled: boolean = false
  @Input() selected: boolean = false
  @Input() disabledText: string
  @Input() size: number = 200
  @Input() team: Team

  @Output() selectionChange = new EventEmitter<boolean>()

  onClick() {
    this.selected = !this.selected;
    this.selectionChange.emit(this.selected)
  }

  constructor() { }

  ngOnInit(): void {
  }
}
