import { Component, OnInit, InjectionToken, Inject, Input } from '@angular/core';

export interface SidebarMenuItem {
  label: string;
  icon: string;
  route: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() items: SidebarMenuItem[];
  
  constructor() { }

  ngOnInit(): void {
  }

}
