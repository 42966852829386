import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Profile } from './_models/profile';
import { AccountService } from './_services/account.service';
import { SidebarMenuItem } from './utils/sidebar/sidebar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  menuItems: SidebarMenuItem[]
  
  profile: Observable<Profile>;
  adminView = false;
  is404 = false;
  
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService) {}

  ngOnInit() {
    this.profile = this.accountService.profile;

    // Menu items are communicated by the specific module running as routing data
    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.menuItems = this.route.snapshot.firstChild.data.sidebar || 
          (this.route.snapshot.firstChild.firstChild && this.route.snapshot.firstChild.firstChild.data.sidebar);
        this.adminView = this.route.snapshot.firstChild.data.admin ||
          (this.route.snapshot.firstChild.firstChild && this.route.snapshot.firstChild.firstChild.data.admin);

        // Check if 404
        let data = this.route.snapshot.firstChild.data;
        this.is404 = data.notFound === true
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  } 

  onSwitchDashboard() {
    if(this.adminView)
      this.router.navigate(["member-dashboard"]);
    else 
      this.router.navigate(["admin-dashboard"]);
  }
}