// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://api.reuseitaly.com/api/v1",
  backendUrl: "https://api.reuseitaly.com",
  invitationJwtSecret: "91ZKtgyZru2qw1Q4e|q1A+JrHLmklwFjJT",
  stripeApiKey: "pk_test_51HeiTRAyTwHcwXgPH63OKE9m8gRt7NEWKthkq5kyJoiefjiibn7BVCrGrGTy5PhGtxXwQYXYFKh9hKtPsXALljdR000XoYCZF4"
};
