import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '../../_services/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {
  @Input() forAdmin;
  @Input() showSwitch;
  @Input() transparent;
  @Output() switch = new EventEmitter<void>();

  constructor(
    private accountService: AccountService,
    private router: Router) { }

  ngOnInit(): void {
  }

  onLogout() {
    this.accountService.signout();
    this.router.navigate(['/account/login']);
  }

  onSwitch() {
    this.switch.emit();
  }
}
