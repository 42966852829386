<div class="mission-manifest" *ngIf="mission">
    <div class="pic" [ngStyle]="{ 'background-image': 'url(' + mission.cover + ')'}" *ngIf="!team">        
        <h1 class="mission-title mat-display-2">
            {{mission.title}}
        </h1>
    </div>
    <div class="mission-sub-cover">
        <div class="mission-info">
            <div class="mission-subtitle mat-title" *ngIf="mission.subtitle">
                {{mission.subtitle}}
            </div>
            <div class="mission-deadline mat-subheading-2" *ngIf="mission.endDate">
                Deadline {{(mission.endDate | date:'mediumDate')}}
            </div>
        </div>
        <button class="join-button" mat-flat-button color="primary" (click)="onJoin()">Join - {{mission.currentPhase ? mission.currentPhase.joinFee : "PRICE"}}€</button>
    </div>
    <div class="mission-description" [innerHTML]="missionDescriptionHtml">
    </div>
</div>
