<div class="dashboard-header" [ngClass]="{ 'dashboard-header-transparent': transparent }">    
    <a class="home-link" href="/">
        <img src="./assets/images/LogoIcon.svg" width="30" height="30"/>
        <h2 class="title mat-title">ReUse Italy
            <span *ngIf="!forAdmin" class="lighter"> Dashboard</span>
            <span *ngIf="forAdmin" class="lighter"> Admin</span>
        </h2>
    </a>
    <app-pill-button *ngIf="showSwitch && !forAdmin" [label]="'Switch to Admin'" [icon]="'group'" (click)="onSwitch()"></app-pill-button>
    <app-pill-button *ngIf="showSwitch && forAdmin" [label]="'Switch to Member'" [icon]="'person'" (click)="onSwitch()"></app-pill-button>
    <a class="signout-link" routerLink="" (click)="onLogout()">
        <mat-icon aria-hidden="false" aria-label="Logout">login</mat-icon>
    </a>
</div>
