<div class="information-box" [ngClass]="{ 'information-box-success': type == 'success', 'information-box-warning': type == 'warning', 'information-box-error': type == 'error' }">
    <div class="information-icon">
        <mat-icon *ngIf="type == 'success'">check</mat-icon>
        <mat-icon *ngIf="type == 'warning'">warning</mat-icon>
        <mat-icon *ngIf="type == 'error'">cancel</mat-icon>
    </div>
    <div class="information-payload">
        <div class="information-title">
            {{title}}
        </div>
        <div class="information-content">
            {{content}}
        </div>
    </div>
</div>
