import { catchError, mergeMap } from 'rxjs/operators';
import { AccountService } from '../_services/account.service'
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

export function refreshToken(accountService: AccountService, router: Router) {
    return () => new Promise<void>(resolve => {
        console.log("Boostrap refreshing token")
        // Attempt to refresh token on app start up (browser refresh) to auto authenticate
        accountService.refreshToken()
            .pipe(mergeMap(token => {
                console.log(JSON.stringify(token));
                // Attempt to get the profile
                return accountService.getProfile()
            }))
            .subscribe({
                // If error need to login
                error: (err) => {
                }
            })
            .add(() => {
                if(accountService.currentProfile)
                    console.log("Token refreshed at start and got profile: " + accountService.currentProfile._id);
                else 
                    console.log("Could not refresh token");
                resolve()
            });
    });
}